.menu {
  ul.social-media {
    float: left;
    li {
      a {
        &:hover, &:visited, &:active {
          background: none;
        }
      }
    }
  }
}

div.highlighter-rouge {
  padding: 1em;
  border: dashed 2px;
  margin: 3em 0;
}

code.highlighter-rouge {
  color: gray;
}
